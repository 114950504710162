import ReactDOM from "react-dom";
import React from "react";
import {Router, Route, hashHistory} from "react-router";
import {StudentPage, StudentStore} from "./student.jsx"
import {StudentsPage} from "./students.jsx"
import {TeacherPage, TeacherInvoice} from "./teacher.jsx"
import {LessonPage} from "./lesson.jsx"
import {GroupsPage, GroupPage} from "./groups.jsx"
import {Firebase} from "./firebase.jsx"

const Empty = () => <div>
</div>

const fire = new Firebase()

ReactDOM.render(
	<Router history={hashHistory}>
		<Route path="/" component={Empty}/>
		<Route path="/students" component={StudentsPage}/>
		<Route path="/student/:id" component={StudentPage}/>
		<Route path="/teacher/:id" component={TeacherPage}/>
		<Route path="/teacher/:id/invoice/from/:from/to/:to" component={TeacherInvoice}/>
		<Route path="/lesson/:id" component={LessonPage}/>
		<Route path="/groups" component={GroupsPage}/>
		<Route path="/group/:id" component={GroupPage}/>
	</Router>,
    document.getElementById('root')
)

console.log("Routes rendered!")

fire.authenticate(user => {
    ReactDOM.render(<section style={{float: 'right'}}>
			<div >{user.email}</div>
			<input type="button" onClick={() => firebase.auth().signOut()} value="Sign out"/>
		</section>,
        document.getElementById('user')
    )
})