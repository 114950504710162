import {map, filter} from "./common.jsx"

export class TeacherStore{
    constructor(db){
        this.db = db;
    }

    watchTeacherDetails(teacherId, callbackOk, callbackFail){
        this.db.ref("/teachers/"+teacherId).on('value',  t => {
            const teacher = t.val()
            this.studentsForTeacher(Object.keys(teacher.studentIds) || [])
                .then(students =>
                    this.groupsForTeacher(Object.keys(teacher.groupIds)).then( groups => callbackOk({...teacher, groups,  students},  callbackFail)))
        })
    }

    studentsForTeacher(studentIds){
        return Promise.all(
            studentIds
                .map(studentId => this.db.ref("/students/"+studentId+"/name").once('value')
                    .then(res => {return {id: studentId, name: res.val()}}))
        )

    }

    groupsForTeacher(groupIds){
        return Promise.all(
            groupIds
                .map(groupId => this.db.ref("/groups/"+groupId+"/name").once('value')
                    .then(res => {return {id: groupId, name: res.val()}}))
        )

    }

    addLesson(lesson){
        return this.db.ref("/lessons").push().set(lesson)
    }

    addGroupLesson(lesson){
        return this.db.ref("/group_lessons").push().set(lesson)
    }

    watchTeacherLessons(teacherId, callbackOk, callbackFail){
        return this.db.ref("/lessons").orderByChild("teacher/id").equalTo(teacherId).on("value", lx => callbackOk(lx.val()))
    }

    watchTeacherGroupLessons(teacherId, callbackOk, callbackFail){
        return this.db.ref("/group_lessons").orderByChild("teacher/id").equalTo(teacherId).on("value", lx => callbackOk(lx.val()))
    }

    watchTeacherGroupLessonsForPeriod(teacherId, from, to, callbackOk){
        return this.db.ref("/group_lessons")
            .orderByChild("time").startAt(from).endAt(to)
            .on("value", lx => {
                    console.log("lessons", lx.val())
                    return callbackOk(filter(lx.val(), x => x.teacher.id === teacherId))
                }
            )
    }

    watchTeacherLessonsForPeriod(teacherId, from, to, callbackOk){
        return this.db.ref("/lessons")
            .orderByChild("time").startAt(from).endAt(to)
            .on("value", lx => {
                    console.log("lessons", lx.val())
                    return callbackOk(filter(lx.val(), x => x.teacher.id === teacherId))
                }
            )
    }

    lessonDelete(lessonKey){
        return this.db.ref("/lessons/"+lessonKey).remove()
    }

    groupLessonDelete(lessonKey){
        return this.db.ref("/group_lessons/"+lessonKey).remove()
    }

    listOptions(callback){
        return this.db.ref("/teachers").once('value', tx => callback(map(tx.val(), (k,v) => {return {id:k, name: v.name}})))
    }

}