import {Link} from 'react-router'
import React from 'react';
import {map, Select, lessonDate} from "./common.jsx"
import {TeacherStore} from "./teacher-store.jsx"
import moment from 'moment'

export class TeacherPage extends React.Component{
    constructor(props){
        super(props)
        this.store = new TeacherStore(firebase.database())
        this.state = { updating: true, groups: [] }
    }

    componentDidMount(){
        this.store.watchTeacherDetails(this.props.params.id,
            teacher => this.setState({teacher, updating: false}),
            alert)

        this.store.watchTeacherLessons(this.props.params.id, lessons => { this.setState({lessons: lessons}) }, alert)
        this.store.watchTeacherGroupLessons(this.props.params.id, lessons => { this.setState({group_lessons: lessons}) }, alert)

    }

    render(){
        if (this.state.updating){
            return <h1>Loading...</h1>
        }else
            return <section>
				<TeacherDetails teacher={this.state.teacher} id={this.props.params.id}/>
                {/*<TeacherFutureLessons lessons={this.state.teacher.futureLessons}/>*/}
				<TeacherLessons lessons={this.state.lessons || []} students={this.state.teacher.students} event={ this.dispatch.bind(this) }/>
				<TeacherGroups lessons={this.state.group_lessons || []} groups={this.state.teacher.groups} event={ this.dispatch.bind(this) }/>
				<TeacherStudents students={this.state.teacher.students}/>
			</section>
    }

    dispatch(event){
        switch (event.type){
            case 'LESSON_ADD':{
                this.store.addLesson({...event.lesson, time: new Date(event.lesson.time).toISOString(), student:{ id: event.lesson.student, name: this.state.teacher.students.find(x => x.id === event.lesson.student).name }, teacher: {id : this.props.params.id, name: this.state.teacher.name }})
                    .then(()=>{}, alert )
            }
            case 'GROUP_LESSON_ADD':{
                this.store.addGroupLesson({...event.lesson, time: new Date(event.lesson.time).toISOString(), group:{ id: event.lesson.group, name: this.state.teacher.groups.find(x => x.id === event.lesson.group).name }, teacher: {id : this.props.params.id, name: this.state.teacher.name }})
                    .then(()=>{}, alert )
            }

            case 'LESSON_DELETE':{
                this.store.lessonDelete(event.key)
                    .then(()=>{}, alert)
            }
            case 'GROUP_LESSON_DELETE':{
                this.store.groupLessonDelete(event.key)
                    .then(()=>{}, alert)
            }
        }
    }
}


class TeacherDetails extends React.Component {


    constructor(props) {
        super(props)
        this.state = {from: moment().set('date', 1).format('YYYY-MM-DD')}
    }

    render() {
        const month = moment().set('date', 1)
        var months = []
        var i;
        for (i = 0; i < 12; i++) {
            months.push({ name: month.format("MMM YY"), id: month.format("YYYY-MM-DD") })
            month.add('months', -1);
        }

        console.log("months", months)

        return <section className="teacher_details">
			<h1>Teacher: {this.props.teacher.name}</h1>
			<Link
				to={"/teacher/"+this.props.id+"/invoice/from/" + moment(this.state.from).format("YYYY-MM-DD") + "/to/" + moment(this.state.from).add('months', 1).format("YYYY-MM-DD")}>Generate Invoice for</Link>&nbsp;

			<Select parent={this} fieldName="from" options={months}/>
		</section>
    }
}

    class TeacherLessons extends React.Component {

    render() {
        const lesson = (key, l) => {
            console.log("lesson", l)
            return <tr key={key}>
				<td>{lessonDate(l.time)}</td>
				<td><StudentLink student={l.student}/></td>
				<td>{l.duration}</td>
				<td><input type="button" value="Delete" onClick={() => {if (confirm("Do you want to DELETE this lesson?")) this.props.event({type:"LESSON_DELETE", key})} }/> </td>

			</tr>
        }
        const lessons = map(this.props.lessons, (k,v) => [k,v]).sort(([k1,v1], [k2,v2]) => new Date(v2.time) - new Date(v1.time))

        return <section className="teacher_lessons">
			<h2>1-2-1 Lessons</h2>
			<table>
				<thead>
				<tr>
					<th>Date</th>
					<th>Student</th>
					<th>Hours</th>
				</tr>
				<tr>
					<td><input type="datetime-local" onChange={(e) => this.setState({time: e.target.value})}/></td>
					<td><select onChange={(e) => this.setState({student: e.target.value})}>
						<option value="">--select--</option>
                        {this.props.students.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
					</select></td>
					<td><input type="number" min="0.5" max="10"
							   onChange={(e) => this.setState({duration: parseFloat(e.target.value)})}/></td>
					<td><input type="button" value="Add"
							   onClick={() => this.props.event({type: 'LESSON_ADD', lesson: this.state})}/></td>
				</tr>
				</thead>
				<tbody>
                { lessons.map(([k,v]) => lesson(k,v)) }
				</tbody>
			</table>
		</section>
    }

}

class TeacherGroups extends React.Component {
    constructor(){
        super();
        this.state = {}
    }

    render() {
        const lesson = (key, l) => {
            console.log("lesson", l)
            return <tr key={key}>
				<td>{lessonDate(l.time)}</td>
				<td><Link to={"/group/"+l.group.id}>{l.group.name}</Link></td>
				<td>{l.duration}</td>
				<td><input type="button" value="Delete" onClick={() => {if (confirm("Do you want to DELETE this lesson?")) this.props.event({type:"GROUP_LESSON_DELETE", key})} }/> </td>

			</tr>
        }

        const lessons = map(this.props.lessons, (k,v) => [k,v]).sort(([k1,v1], [k2,v2]) => new Date(v2.time) - new Date(v1.time))
        console.log("lessons", lessons)
        return <section className="teacher_lessons">
			<h2>Group Lessons</h2>
			<table>
				<thead>
				<tr>
					<th>Date</th>
					<th>Group</th>
				</tr>
				<tr>
					<td><input type="datetime-local" onChange={(e) => this.setState({time: e.target.value})}/></td>
					<td><Select parent={this} fieldName="group" options={this.props.groups}/></td>
					<td><input type="number" min="0.5" max="10"
							   onChange={(e) => this.setState({duration: parseFloat(e.target.value)})}/></td>
					<td><input type="button" value="Add"
							   onClick={() => this.props.event({type: 'GROUP_LESSON_ADD', lesson: this.state})}/></td>
				</tr>
				</thead>
				<tbody>
                { lessons.map(([k,v]) => lesson(k,v)) }
				</tbody>
			</table>
		</section>
    }

}


// class TeacherFutureLessons extends React.Component {
//
//     render() {
//         const lesson = (l) => <tr key={l.time}>
// 			<td>{l.time}</td>
// 			<td><StudentLink student={l.student}/></td>
// 			<td>{l.hours}</td>
// 			<td className={"lesson_status_"+l.status}>{l.status}</td>
// 			<td><Link to={"/lesson/"+l.id}>Edit</Link></td>
// 		</tr>
//
//         return <section className="teacher_lessons">
// 			<h2>Future Lessons</h2>
// 			<table>
// 				<thead>
// 				<tr>
// 					<th>Date</th>
// 					<th>Student</th>
// 					<th>Hours</th>
// 					<th>Status</th>
// 				</tr>
//                 {/*<tr>*/}
//                 {/*<td><input type="datetime-local" onChange={(e) => this.setState({time: e.target.value})}/></td>*/}
//                 {/*<td><input type="text" onChange={(e) => this.setState({teacherName: e.target.value})}/></td>*/}
//                 {/*<td><input type="number" min="0.5" max="10"*/}
//                 {/*onChange={(e) => this.setState({hours: parseFloat(e.target.value)})}/></td>*/}
//                 {/*<td><input type="button" value="Add" onClick={this.addLesson.bind(this)}/></td>*/}
//                 {/*</tr>*/}
// 				</thead>
// 				<tbody>
//                 {this.props.lessons.map(lesson)}
// 				</tbody>
// 			</table>
// 		</section>
//     }
// }
const StudentLink = ({student}) => <Link to={"/student/"+student.id}>{student.name}</Link>

const TeacherStudents = ({students}) =><section className="teacher_students">
	<h2>Students</h2>
	<table>
		<tbody>
        {students.map(s => <tr key={s.id}><td><StudentLink student={s}/></td></tr>)}
		</tbody>
	</table>
</section>

export class TeacherInvoice extends React.Component{

    constructor(props){
        super(props)
        this.store = new TeacherStore(firebase.database())
        this.state = { group_lessons : [], lessons: [], teacher: {}}
    }

    componentDidMount(){
        this.store.watchTeacherDetails(this.props.params.id,
            teacher => this.setState({teacher, updating: false}),
            alert)

        this.store.watchTeacherLessonsForPeriod(this.props.params.id, this.props.params.from, this.props.params.to , lessons => { this.setState({lessons: lessons}) }, alert)
        this.store.watchTeacherGroupLessonsForPeriod(this.props.params.id, this.props.params.from, this.props.params.to, lessons => { this.setState({group_lessons: lessons}) }, alert)


    }


    render() {
        const lesson = (key, l) => {
            return <tr key={key}>
				<td>{lessonDate(l.time)}</td>
				<td>
                    {l.group ?
						<div>Group: <Link to={"/group/" + l.group.id}>{l.group.name}</Link></div>
                        :
						<div>1-2-1: <StudentLink student={l.student}/></div>
                    }
				</td>
				<td>{l.duration}</td>
			</tr>
        }
        const lessons = this.state.group_lessons.concat(this.state.lessons).sort(([k,v1], [k2, v2] ) => v1.time < v2.time ? -1 : 1 )
        console.log(lessons.map(([k,v])=> v.time))
        const totalHours = lessons.map(([key,lesson]) => lesson.duration).reduce( (x,y)=> x+y, 0)

        return <section className="teacher_lessons">
			<h2>Teacher {this.state.teacher.name} - {this.props.params.from} to {this.props.params.to}</h2>
			<table>
				<thead>
				<tr>
					<th>Date</th>
					<th>Line Item</th>
					<th>Hours</th>
				</tr>
				</thead>
				<tbody>
                {lessons.map(([k,v]) => lesson(k,v))}
				</tbody>
				<tfoot>
				<td colSpan={2}><b>Total</b></td>
				<td><b>{totalHours}</b></td>
				</tfoot>
			</table>
		</section>
    }


}