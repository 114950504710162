import {Link} from 'react-router'
import React from 'react'
import {StudentStore} from "./student-store.jsx"
import {map, lessonDate} from "./common.jsx"


class StudentDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {details: props.details, edited: false}
    }

    componentWillReceiveProps(props){
        this.setState({details: props.details, edited: false})
    }

    render() {
        const details = this.state.details
        if (this.state.edited) return this.edited(details); else return this.readOnly(details);
    }



    edited(d) {
        console.log("edited", d)

        const Input = (fieldName) =>
            <input value={this.state.details[fieldName]}  onChange={(e) => this.setState({ details : { ...this.state.details,[fieldName] : e.target.value} })}/>

        return <section className="student_details">
            Name {Input("name")}
            <ul>
                <li><label>Paid hours left: </label>{d.paidHoursLeft}</li>
                <li><label>Email: </label>{Input("email")}</li>
                <li><label>Phone: </label>{Input("phone")}</li>
            </ul>
            <input type="button" value="Cancel" onClick={() => this.setState({ details: this.props.details, edited: false})}/>
            <input type="button" value="Save" onClick={() => this.props.event({type: "STUDENT_DETAILS_SAVE", details: this.state.details})}/>
        </section>
    }

    readOnly(d) {
        return <section className="student_details">
            <h1>Student: {d.name}</h1>
            <ul>
                <li><label>Paid hours left: </label>{d.paidHoursLeft}</li>
                <li><label>Email: </label><a href={"mailto:" + d.email}>{d.email}</a></li>
                <li><label>Phone: </label><a href={ "tel:" + d.phone}>{d.phone}</a></li>
            </ul>
            <input type="button" value="Edit" onClick={() => this.setState({edited: true})}/>
        </section>
    }
}

class StudentLessons extends React.Component {

    render(){

        const
            lesson = (key, l) => <tr key={key}>
                <td>{lessonDate(l.time)}</td>
                <td>{l.duration}</td>
                <td><Link to={"/teacher/"+l.teacher.id}>{l.teacher.name}</Link></td>
            </tr>

        const lessons = map(this.props.done, (k,v) => [k,v]).sort(([k1,v1], [k2,v2]) => new Date(v2.time) - new Date(v1.time))

        return <section className="student_lessons">
            <h2>Lessons</h2>
            <table>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Hours</th>
                    <th>Teacher</th>
                </tr>
                </thead>
                <tbody>
                { lessons.map(([k,v]) => lesson(k,v) ) }
                </tbody>
            </table>
        </section>
    }
}

class PaymentRow extends React.Component{
    field(name, f = x => x){
        return (e) => this.setState({ [name] : f(e.target.value) })
    }

    constructor(props){
        super(props);
        this.state = { ...props.payment }

    }

    componentWillReceiveProps(props){
        this.setState ( { ...props.payment } )
    }

    render() {
        const payment = this.state
        console.log("Rendering payment:", payment)
        if (payment.edited){
            return <tr>
                <td><input type="date" onChange={this.field('date')} value={this.state.date}/></td>
                <td><input type="number" min="0.5" max="1000" onChange={this.field('hours', parseFloat)} value={this.state.hours}/></td>
                <td><input type="text" onChange={this.field('source')} value={this.state.source}/></td>
                <td><input type="text" onChange={this.field('ref')} value={this.state.ref}/></td>
                <td><input type="button" value="Save" onClick={() => this.props.event({ type:'PAYMENT_SAVE', key: this.props.id, payment: {...this.state, edited: false }})}/>
                    <input type="button" value="Cancel" onClick={() => this.setState({ ...this.props.payment, edited: false })}/>
                </td>

            </tr>
        } else {
            return <tr>
                <td>{payment.date}</td>
                <td>{payment.hours}</td>
                <td>{payment.source}</td>
                <td>{payment.ref}</td>
                <td><input type="button" value="Edit" onClick={() => this.setState({ edited: true })}/></td>
                <td><input type="button" value="Delete" onClick={() => {if (confirm('Are you sure you want to DELETE this payment?')) this.props.event({type: 'PAYMENT_DELETE', payment: this.props.payment, key: this.props.id})}}/></td>
            </tr>
        }
    }

}

class StudentPayments extends React.Component {

    addPayment(){
        this.props.event({ type: "PAYMENT_ADD", payment: { ...this.state } })
    }

    field(name, f = x => x){
        return (e) => this.setState({ [name] : f(e.target.value) })
    }


    render() {
        console.log("this.props.payments", this.props.payments)
        const payments = this.props.payments
        return <section className="student_payments">
            <h2>Payments</h2>
            <table>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Hours</th>
                    <th>Source</th>
                    <th>Ref</th>
                </tr>
                <tr>
                    <td><input type="date" onChange={this.field('date')}/></td>
                    <td><input type="number" min="0.5" max="1000" onChange={this.field('hours', parseFloat)}/></td>
                    <td><input type="text" onChange={this.field('source')}/></td>
                    <td><input type="text" onChange={this.field('ref')}/></td>
                    <td><input type="button" value="Add" onClick={this.addPayment.bind(this)}/></td>
                </tr>
                </thead>
                <tbody>
                {

                    map(payments, (k,v) => [k, v])
                        .sort(([ka, a],[kb, b]) => Date.parse(b.date) - Date.parse(a.date))
                        .map(([key, p]) => <PaymentRow key={key} payment={p} id={key} event={this.props.event} />)
                }
                </tbody>
            </table>
        </section>
    }
}


export class StudentPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {}
        this.store = new StudentStore(firebase.database())
    }

    componentDidMount(){
        this.store.fetchStudent(this.props.params.id)
            .then(student => this.setState({student, updating: false}), err => alert("Can't load student " + this.props.params.id + " - "+ err))
        this.store.watchStudent(this.props.params.id, student => this.setState({student, updating: false}))
        this.store.watchStudentLessons(this.props.params.id, lessons => this.setState({lessons, updating: false}), alert)
    }

    render() {
        if (this.state.updating){
            return <h1>Updating...</h1>
        } else if (this.state.student) {
            const student = this.state.student
            console.log("Rendering", student)
            return <section className="studentPage">
                <StudentDetails  details={student} event={this.dispatch(student)} />
                <StudentPayments payments={student.payments || {}} event={this.dispatch(student)}/>
                <StudentLessons done={this.state.lessons || {}} />
            </section>
        } else {
            return <h1>Loading...</h1>
        }
    }



    dispatch(student) {
        const noop = {}
        const showError = err => alert("Error: " + err);

        return (event) => {
            console.log("Event:", event)
            switch (event.type) {
                case "STUDENT_DETAILS_SAVE":{
                    this.setState({updating:true })
                    return this.store.saveStudentDetails(student, this.props.params.id, event.details)
                        .then( noop, showError )
                }
                case "PAYMENT_SAVE": {
                    this.setState({updating:true })
                    return this.store.savePayment(student, this.props.params.id, event.key, event.payment)
                        .then( noop, showError)

                }
                case "PAYMENT_ADD": {
                    this.setState({updating:true })
                    return this.store.addPayment(student, this.props.params.id, event.payment)
                        .then( noop, showError)
                }
                case "PAYMENT_DELETE": {
                    this.setState({updating:true })
                    return this.store.deletePayment( student, this.props.params.id, event.payment, event.key)
                        .then( noop, showError)
                }
                default:{
                    throw "Unsupported event"+event
                }
            }

        }
    }


}