import React from 'react';

export class LessonPage extends React.Component{
	constructor(props){
		super(props)
		this.state = { lesson: null}
		new LessonStore().get(props.params.id).then(l => this.setState({lesson: l}))
	}

	componentWillReceiveProps(newProps){
		new LessonStore().get(newProps.params.id).then(l => this.setState({lesson: l}))
	}

	confirmDone(l){
		return <section>
			<h1>Lesson</h1>
			Status: {l.status}<br/>
			Student: {l.student.name}<br/>
			Teacher: {l.teacher.name}<br/>
			Date/Time: {l.time}<br/>
			Duration (hours): {l.hours}<br/>
			<section>
				<button className="mainAction">Confirm Done</button>
				<button className="dangerousAction">Cancel (LATE)</button>
				<button>Cancel</button>
			</section>
		</section>
	}

	render(){
		const l = this.state.lesson
		if (l){
			const time = Date.parse(l.time)
			console.log("time", time)
			if (time < new Date())
				return this.confirmDone(l)
			else
				return <ul className="lesson">
					<li><h1>Lesson</h1></li>
					<li>Status: {l.status}</li>
					<li>Student: {l.student.name}</li>
					<li>Teacher: {l.teacher.name}</li>
					<li><div>Date/Time: <input type="datetime-local" defaultValue={l.time} onChange={(e) => this.setState( {lesson: {...this.state.lesson, time: Date.parse(e.target.value)}})}></input></div></li>
					<li><div>Duration (hours): <input type="number" defaultValue={l.hours} onChange={(e) => this.setState( {lesson: {...this.state.lesson, hours: parseInt(e.target.value)}})}></input></div></li>
					<li><LessonsPageActionButtons lesson={l}/></li>
				</ul>
		} else return <h1>Loading...</h1>
	}
}

const bookable = (lesson) => {
	return lesson.time && lesson.hours
}

const LessonsPageActionButtons = ({lesson}) => {
	switch(lesson.status){
		case "TBC":
			return <section className="actions">
				<button className="mainAction" disabled={!bookable(lesson)}>Book</button>
				<button>Save</button>
			</section>
		case "BOOKED":
			return <section>
				<button >Cancel</button>
				<button>Save</button>
			</section>
	}
}

class LessonStore{
	constructor(){
		this.lessons = {
			1: { id:1, time:"", student:{name:"Adam Slodowy", id:1}, teacher: {name:"Anna Wanna", id:1}, hours: 1, status: "TBC"},
			2: { id:2, time:"2017-01-05T18:30", student:{name:"Adam Slodowy", id:1}, teacher: {name:"Anna Wanna", id:1}, hours: 1, status: "BOOKED"}
		}
	}

	get(id){
		return new Promise((ok,fail) => setTimeout(() => ok(this.lessons[id]),300))
	}
	book(id){
		const do_book = () =>{

		}
		return new Promise((ok, fail) => setTimeout(doBook, 300 ))
	}
}