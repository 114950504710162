import React from "react";
import {Link} from 'react-router'
import {map, Select, lessonDate} from './common.jsx'
import {TeacherStore} from "./teacher-store.jsx"
import { browserHistory } from 'react-router';
import dateFormat from 'dateformat';

export class GroupsPage extends React.Component{

    constructor(props){
        super(props)
        this.store = new GroupStore(firebase.database())
        this.teacherStore = new TeacherStore(firebase.database())

        this.state = { updating: true, groups : [], teachers : [] }
    }

    componentDidMount(){
        this.store.watchList(
            list => this.setState({groups: list, updating: false})
        )
        this.teacherStore.listOptions(teachers => this.setState({teachers}))

    }

    render(){
        if(this.state.updating) {
            return <h1>Updating...</h1>
        }else {
            return <table>
                <thead>
                <tr>
                    <th>Group</th>
                    <th>Start</th>
                    <th>Duration(hours)</th>
                    <th><input type="button" value="Add" onClick={() => browserHistory.push('/#/group/'+this.store.addGroup()) }/></th>
                </tr>
                </thead>
                <tbody>
                {map(this.state.groups, (key, group) =>
                    <tr key={key}>
                        <td>{group.start}</td>
                        <td><Link to={"/group/"+key}>{group.name}</Link></td>
                        <td>{group.durationHours}</td>
                        <td>{(this.state.teachers.find(x => x.id === group.teacher) || {}).name || ""}</td>
                        <td>{group.active ? "active" : "archived"}</td>
                    </tr>
                )}
                </tbody>
            </table>
        }
    }
}

class GroupEdit extends React.Component{

    constructor(props){
        super(props);
        this.state = {...props.group }
    }

    render(){
        const group = this.state
        return <section>
            Group: <input type="text" value={group.name} onChange={(e) => this.setState({ name: e.target.value })}/>
            <ul>

                <li>Start: <input type="date" value={group.start} onChange={(e) => this.setState({ start: e.target.value })}/></li>
                <li>Duration(hours): <input type="number" value={group.durationHours} onChange={(e) => this.setState({ durationHours: e.target.value })}/></li>
                <li>Active <input type="checkbox" checked={group.active} onChange={(e) => this.setState({ active: e.target.checked })}/></li>
                <li>Teacher <Select parent={this} fieldName="teacher" options={this.props.teachers || {}} /></li>
            </ul>

            <input type="button" onClick={() => this.props.event({type:'GROUP_SAVE', group: this.state}) } value="Save"/>
            <input type="button" onClick={() => this.props.event({type:'CANCEL_EDITING'}) } value="Cancel"/>

        </section>

    }
}

export class GroupPage extends React.Component{

    constructor(props){
        super(props)
        this.store = new GroupStore(firebase.database())
        this.teacherStore = new TeacherStore(firebase.database())
        this.state = { updating: true, group : {}, teachers: [], group_lessons : {} }
    }

    componentDidMount(){
        this.store.watchGroup(this.props.params.id,
            group => this.setState({ group, updating: false, editing:false})
        )

        this.teacherStore.listOptions(teachers => this.setState({teachers}))
        this.store.watchGroupLessons(this.props.params.id, group_lessons => this.setState({ group_lessons}))
    }

    dispatch(event){
        switch (event.type){
            case "GROUP_SAVE":{
                this.setState({ editing: false })
                this.store.save(this.props.params.id, event.group)
            }
            case "CANCEL_EDITING":{
                this.setState({ editing: false })
            }
        }
    }

    render(){
        if(this.state.updating) {
            return <h1>Updating...</h1>
        }else
        if( this.state.editing ){
            return <GroupEdit group={this.state.group} teachers={this.state.teachers} event={e => this.dispatch(e)}/>
        }else {
            const group = this.state.group
            const lessons = map(this.state.group_lessons, (k,v) => [k,v]).sort(([k1,v1], [k2,v2]) => new Date(v2.time) - new Date(v1.time))

            return <div>
                <section>
                    <h1>Group: {group.name}</h1>
                    <ul>
                        <li>Start: {group.start}</li>
                        <li>Duration(hours): {group.durationHours}</li>
                        <li>Teacher: {(this.state.teachers.find(x => x.id === group.teacher) || {}).name || ""}</li>
                        <li>{group.active ? "Active" : "Archived"}</li>
                    </ul>
                    <input type="button" onClick={ () => this.setState({ editing: true }) } value="Edit"/>
                </section>
                <section>
                    <h2>Lessons</h2>
                    <table>
                        <thead>
                        <th>Date</th>
                        <th>Duration</th>
                        <th>Teacher</th>
                        </thead>
                        <tbody>
                        { lessons.map(([key, l]) =>
                            <tr key={key} >
                                <td>{lessonDate(l.time)}</td>
                                <td>{l.duration} h</td>
                                <td><Link to={"/teacher/"+ l.teacher.id}>{l.teacher.name}</Link></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </section>
            </div>
        }
    }
}

class GroupStore{
    constructor(db){
        this.db = db;
    }

    watchGroup(groupId, callback){
        this.db.ref("/groups/"+groupId).on('value', x => callback(x.val()))
    }

    watchList(callback){
        this.db.ref("/groups").on('value', x => callback(x.val()))
    }

    save(groupId, group){
        if (group.teacher) this.db.ref("/teachers/"+group.teacher+"/groupIds").update({[groupId]: groupId})
        return this.db.ref("/groups/"+groupId).set(group)
    }

    addGroup(){
        return this.db.ref("/groups").push({
            name: "New Group",
            start: "",
            durationHours: 0,
            students:{},
            active: true
        }).key
    }


    watchGroupLessons(groupId, callback){
        return this.db.ref("/group_lessons").orderByChild("group/id")
            .equalTo(groupId).on("value",
                lx => callback(lx.val())
            )
    }


}