import React from 'react'
import dateFormat from "dateformat"


export function map(obj, f){
    return obj ? Object.keys(obj).map(key => f(key, obj[key])) : []
}

export function filter(obj, f){
    return map(obj, (k,v) => [k,v]).filter(
        ([key, val]) => f(val)
    )
}

export const Select = (props) => {
    return <select onChange={(e) => props.parent.setState({[props.fieldName]: e.target.value})} value={props.parent.state[props.fieldName]}>
        <option value="">--select--</option>
        {props.options.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
    </select>
}

export function lessonDate(dateString){
    const date = new Date(dateString)
    return date.getYear() === new Date().getYear() ?
        dateFormat(date, "ddd, d mmm, HH:MM")
        :
        dateFormat(date, "ddd, d mmm yyyy, HH:MM")
}