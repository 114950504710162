export class Firebase{
    constructor(){
        const config = {
            apiKey: "AIzaSyDtzL9KzGkboYK89GjJl4i434gTjy8WY-4",
            authDomain: "school-f3b91.firebaseapp.com",
            databaseURL: "https://school-f3b91.firebaseio.com",
            projectId: "school-f3b91",
            storageBucket: "school-f3b91.appspot.com",
            messagingSenderId: "274012393859"
        };
        firebase.initializeApp(config);
    }

    authenticate(onSuccess){
        firebase.auth().onAuthStateChanged(user => {
            if (user === null) {
                const provider = new firebase.auth.GoogleAuthProvider();
                provider.addScope('https://www.googleapis.com/auth/plus.login');
                firebase.auth().signInWithRedirect(provider)
            }else {
                onSuccess(user)
            }
        })
    }

    user(){
        return firebase.auth().currentUser
    }
}

// Initialize Firebase

