import {map} from "./common.jsx"

export class StudentStore{
    constructor(db) {
        this.db = db;
    }

    savePayment(student, studentId, paymentKey, payment){
        const original = student.payments[paymentKey]
        student.payments[paymentKey] = payment
        // student.paidHoursLeft += payment.hours - original.hours
        return this.db.ref("/students/"+studentId+"/payments/"+paymentKey).set(payment)
    }

    addPayment(student, studentId, payment){
        // this.db.ref("/students/"+studentId).set({...student, paidHoursLeft: student.paidHoursLeft  + payment.hours})
        return this.db.ref("/students/"+studentId +"/payments").push(payment)
    }

    deletePayment(student, studentId, payment, paymentKey) {
        // this.db.ref("/students/"+studentId).set({...student, paidHoursLeft: student.paidHoursLeft  - payment.hours})
        return this.db.ref("/students/"+studentId+"/payments/"+paymentKey).remove()
    }


    fetchStudent(studentId) {
        return this.db.ref("/students/" + studentId).once('value').then(x => x.val())
    }

    watchStudent(studentId, f){
        return this.db.ref("/students/"+studentId).on('value', x => f(x.val()))
    }

    watchStudentLessons(studentId, callbackOk, callbackFail){
        return this.db.ref("/lessons").orderByChild("student/id").equalTo(studentId).on("value", lx => callbackOk(lx.val()) )
    }




saveStudentDetails(student, studentId, details) {
        const newStudent = {
            ...student,
            ...details
        }
        return this.db.ref("/students/"+studentId).set(newStudent)
    }

    list(){
        return new Promise((res, rej) => this.db.ref("/students")
            .once('value', snap => {
                const students = snap.val()
                console.log("STUDENTS", students)
                res(Object.keys(students).map(key => [key, students[key]]))
            }))
    }

    addUser(){
        return this.db.ref("/students").push({
                name : "New User",
                email : "",
                lessonsDone : {},
                paidHoursLeft : 0,
                payments : {},
                phone : ""
            }
        ).key;
    }
}
