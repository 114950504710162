import React from "react";
import {Link} from 'react-router'

import {StudentStore} from "./student-store.jsx"
import { browserHistory } from 'react-router';

export class StudentsPage extends React.Component{

    constructor(props){
        super(props)
        this.store = new StudentStore(firebase.database())
        this.state = { updating: true, students : [] }
    }

    componentDidMount(){
        this.store.list().then(
            list => this.setState({students: list, updating: false})
            ,err => alert(err)
        )
    }

    render(){
        if(this.state.updating) {
            return <h1>Updating...</h1>
        }else {
            return <table>
                <thead>
                <tr>
                    <th>Student</th>
                    <th><input type="button" value="Add" onClick={() => {try {browserHistory.push('/#/student/'+this.store.addUser())} catch(e) { console.error(e) }} }/></th>
                </tr>
                </thead>
                <tbody>
                {this.state.students.map(([k, s]) =>
                    <tr key={k}>
                        <td><Link to={"/student/"+k}>{s.name}</Link></td>
                        <td><input type="button" value="Delete" /></td>
                    </tr>
                )}
                </tbody>
            </table>
        }
    }
}